import React from "react"

const ReturnPolicy = () => {
  return (
    <div style={styles.container}>
      <h2 style={styles.header}>🔄 교환/반품 안내</h2>
      <table style={styles.table}>
        <tbody>
          <tr>
            <th>회원 유형</th>
            <th>반품/교환 비용</th>
          </tr>
          <tr>
            <td>✅ 멤버십 회원</td>
            <td>
              <strong>무료 반품/교환 가능</strong>
            </td>
          </tr>
          <tr>
            <td>🚫 비회원 ( 멤버십 미가입)</td>
            <td>
              - 반품 후 총 주문금액 <strong>19,800원 미만</strong>: 반품비
              5,000원 <br />- 반품 후 총 주문금액 <strong>19,800원 이상</strong>
              : 반품비 2,500원
            </td>
          </tr>
        </tbody>
      </table>

      <h3>📆 교환/반품 신청 기준일</h3>
      <ul>
        <li>
          <strong>단순 변심</strong>: 제품 수령 후{" "}
          <span style={styles.highlight}>30일 이내</span> (단, 고객 부담)
        </li>
        <li>
          <strong>상품 불량/표시광고와 다른 경우</strong>:<br />- 상품 수령 후{" "}
          <span style={styles.highlight}>3개월 이내</span> <br />- 또는 사실을
          알게 된 날부터 <span style={styles.highlight}>30일 이내</span>{" "}
          청약철회 가능
        </li>
      </ul>

      <h3>⛔ 교환/반품 제한사항</h3>
      <ul>
        <li>
          주문/제작 상품으로 <strong>이미 제작이 진행된 경우</strong>
        </li>
        <li>
          포장을 개봉하여 사용/설치 완료된 경우 (단, 확인을 위한 개봉은 예외)
        </li>
        <li>사용 흔적, 일부 소비로 상품 가치가 감소한 경우</li>
        <li>세트상품 일부 사용, 구성품 누락, 파손/오염된 경우</li>
        <li>모니터 해상도 차이로 인한 단순 변심 반품 요청</li>
      </ul>

      <h2>🏢 판매자 정보</h2>
      <p>
        <strong>판매자:</strong> 고객센터 📞
      </p>

      <h2>🔍 상품 인증 정보</h2>
      <ul>
        <li>
          <strong>KC 인증</strong>: 전기용품 안전확인 (B052R346-6001)
        </li>
        <li>
          <strong>안전 기준 충족</strong>: 화재·감전 위험 방지를 위한 안전기준
          적합 제품
        </li>
      </ul>
    </div>
  )
}

// 스타일 객체
const styles = {
  container: {
    fontFamily: "Arial, sans-serif",
    padding: "20px",
    lineHeight: "1.6",
  },
  header: {
    backgroundColor: "#357ddc",
    color: "white",
    padding: "10px",
    borderRadius: "5px",
  },
  table: { width: "100%", borderCollapse: "collapse", margin: "15px 0" },
  highlight: { fontWeight: "bold", color: "#d9534f" },
}

export default ReturnPolicy
