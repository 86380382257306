import React from "react"
import Nav from "react-bootstrap/Nav"
import { useRecoilState, useRecoilValue } from "recoil"
import { useNavigate } from "react-router-dom"
import "../../CSS/Header/TopNav.css" // Import the CSS file
import axios from "axios"
import {
  admin,
  login,
  mobile,
  selectCategory,
  serverUrl,
  shoppingCart,
  UAccessToken,
} from "../../App"

function TopNav() {
  const navi = useNavigate()
  const isMobile = useRecoilValue(mobile)
  const accessToken = useRecoilValue(UAccessToken)
  const [isLogin, setIsLogin] = useRecoilState(login)
  // const username = useRecoilValue(UNickName);
  const username = localStorage.getItem("nickname")
  const [, setSelectCate] = useRecoilState(selectCategory)
  console.log("username", username)
  const refreshToken = localStorage.getItem("refreshToken")
  // 창을 닫고 다시 켤 경우 사용자 정보를 로컬 스토리지에서 다시 받아옴
  const isAdmin = useRecoilValue(admin)
  const [cart, setCart] = useRecoilState(shoppingCart)
  // console.log(username);
  const baseUrl = useRecoilValue(serverUrl)
  // const handleLogout = async () => {
  //   localStorage.clear();
  //   try {
  //     await axios.post(`${baseUrl}/api/v1/auth/logout`, null, {
  //       headers: {
  //         Authorization: `Bearer ${refreshToken}`,
  //       },
  //     });
  //     setIsLogin(false);
  //     window.location.reload();
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };
  const handleLogout = () => {
    localStorage.clear()
    setCart([])
    const logout = async () => {
      await axios
        .post(`${baseUrl}/api/v1/auth/logout`, {}, { withCredentials: true })
        .then((response) => {
          if (response.status === 200) {
            console.log("Logged out successfully")
            setIsLogin(false)
          } else {
            console.error("Logout failed")
          }
        })
        .catch((error) => console.error("Error during logout:", error))
    }
    logout()
  }

  const handleNav = (link, cate) => {
    navi(link)
    setSelectCate(cate)
  }

  return (
    <div>
      <Nav className="navbar-custom">
        {isLogin ? (
          <>
            <p className="nav-link">{username}님</p>
            <Nav.Item onClick={handleLogout}>
              <Nav.Link>로그아웃</Nav.Link>
            </Nav.Item>
            <Nav.Item onClick={() => handleNav("/MyPage/Orders", "주문조회")}>
              <Nav.Link>마이페이지</Nav.Link>
            </Nav.Item>
          </>
        ) : (
          <>
            <Nav.Item onClick={() => navi("/Login")}>
              <Nav.Link>로그인</Nav.Link>
            </Nav.Item>
          </>
        )}

        {/* 로그인 후 , 관리자 인 경우만 보이도록  */}
        {isAdmin && !isMobile && (
          <Nav.Item
            onClick={() => handleNav("/Admin/DashBoard", "관리자 대시보드")}
          >
            <Nav.Link>관리자</Nav.Link>
          </Nav.Item>
        )}
      </Nav>
    </div>
  )
}

export default TopNav
