import React, { useEffect, useState } from "react"
import StarRating from "./StarRating"
import share from "./Img/share.png"
import ShareModal from "./ShareModal"
import { Button, InputGroup } from "react-bootstrap"
import ItemInfo from "./ItemInfo"
import { login, shoppingCart } from "../../../App"

import { useRecoilState, useRecoilValue } from "recoil"
import "../../../CSS/ConBox/Item/ItemPage.css"
import { useLocation, useNavigate, useParams } from "react-router-dom"
import { client } from "../../API/api"
import styled from "styled-components"
import { Input } from "reactstrap"
import RelativeProduct from "./RelativeProduct"
import ModalComponent from "../../Common/ModalComponent"
import { MoreOption } from "./MoreOption"

const InfoText = styled.span`
  font-size: 16px;
  color: #666;

  .label {
    color: black;
  }
`

const options = [
  {
    optionName: "1kg",
    price: "10,000",
  },
  { optionName: "2kg", price: "15,000" },
]

const ItemPage = () => {
  const { pathname } = useLocation()
  //첫 렌더링시 맨위로
  useEffect(() => window.scrollTo(0, 0), [pathname])
  const [modalShow, setModalShow] = useState(false)
  const [shopping, setShopping] = useRecoilState(shoppingCart)
  const { id } = useParams()
  const navi = useNavigate()
  const [item, setItem] = useState({})
  const [count, setCount] = useState(1)
  const [price, setPrice] = useState("")
  const [isAlertModal, setIsAlertModal] = useState(false)
  const isLogin = useRecoilValue(login)
  const userId = localStorage.getItem("userId")

  // 다른 아이템 선택시 개수 초기화
  useEffect(() => {
    setCount(1)
  }, [id])
  //최근 본 기록
  const recentViewedItems = (itemId, photoUrl, name, price) => {
    // localStorage에서 'recentViewedList' 가져오기 (JSON으로 파싱)
    let recentViewedList =
      JSON.parse(localStorage.getItem("recentViewedList")) || []

    // 새로운 아이템 객체 생성
    const newItem = { itemId, photoUrl, name, price }

    // 이미 있는 아이템인지 확인 (중복 방지)
    const existingIndex = recentViewedList.findIndex(
      (item) => item.itemId === itemId
    )
    if (existingIndex !== -1) {
      // 이미 존재하는 아이템일 경우, 해당 아이템을 제거
      recentViewedList.splice(existingIndex, 1)
    }

    // 새로운 아이템을 배열의 맨 앞에 추가
    recentViewedList.unshift(newItem)

    // 최대 5개까지만 유지
    if (recentViewedList.length > 5) {
      recentViewedList.pop() // 배열의 마지막 항목 제거
    }

    // 업데이트된 배열을 localStorage에 저장 (JSON 문자열로 변환)
    localStorage.setItem("recentViewedList", JSON.stringify(recentViewedList))

    console.log("recentViewedList", recentViewedList)
  }

  //장바구니 로컬 스토리지추가
  //todo:카트 서버작업추가
  const setLocalStorageItem = (key, value) =>
    localStorage.setItem(key, JSON.stringify(value))
  //장바구니 추가

  const addShoopingServer = async (productId) => {
    try {
      console.log("productId", productId)
      await client.put("/Cart/createCarts", [
        { productId, userId: localStorage.getItem("userId"), quantity: count },
      ])
    } catch (error) {
      console.error(error.response.data.message)
    }
  }
  // 변경이 있을때마다 서버에서 가져오기
  const getShoopingServer = async () => {
    // 서버의 장바구니 데이터 가져오기
    const cartResponse = await client.get(`/Cart/findAllCart?userId=${userId}`)
    const serverCartData = cartResponse.data.carts
    localStorage.setItem("shoppingCart", JSON.stringify(serverCartData))
    setShopping(serverCartData)
  }

  const addShopping = async (nowitem) => {
    // 장바구니에 이미 있는지 확인
    if (isLogin) {
      await addShoopingServer(nowitem.id)
      await getShoopingServer()
    } else {
      const newList = shopping.map((item) =>
        item.id === nowitem.id
          ? { ...item, quantity: count } // 기존 상품이면 수량 업데이트
          : item
      )

      const isExist = shopping.some((item) => item.id === nowitem.id)

      if (!isExist) {
        newList.push({
          id: nowitem.id,
          productName: nowitem.name, // productName 사용
          productImageUrl: nowitem.photoUrl, // photoUrl 사용
          price: nowitem.price,
          quantity: count,
        })
      }

      setLocalStorageItem("shoppingCart", newList) // 로컬 스토리지 업데이트
      setShopping(newList) // 상태 업데이트
    }

    alert(`${nowitem.name}이(가) 장바구니에 추가되었습니다.`)
  }
  useEffect(() => {
    const fetchAndSetData = async () => {
      const response = await client.get(`/Product/getProductById?id=${id}`)
      //최근 본 상품 표시
      recentViewedItems(
        response.data.id,
        response.data.photoUrl,
        response.data.name,
        response.data.price
      )
      setItem(response.data)
    }
    fetchAndSetData()
  }, [id])

  useEffect(() => {
    if (item.price) {
      setPrice(item.price.toLocaleString())
    }
  }, [item])

  const handleBuy = () => {
    if (!isLogin) {
      alert("로그인이 필요합니다.")
      navi("/Login")
    }
    // 구매 개수가 20개 이하일때만(대량구매)
    else if (count < 20) {
      navi("/Pay", {
        state: {
          item: [
            {
              id: item.id,
              productName: item.name,
              productImageUrl: item.photoUrl,
              productId: item.id,
              price: item.price,
              quantity: count,
            },
          ],
        },
      })
    } else {
      setIsAlertModal(true)
    }
  }
  const handleCountChange = (e) => {
    let value = e.target.value

    // 입력 값이 0이면 자동으로 1로 설정
    if (value === "0") {
      value = "1"
    }

    setCount(Number(value))
  }
  console.log(item)
  return (
    <div className="container">
      <div className="itemPage">
        <img src={item.photoUrl} alt={item.name} className="imageItem" />

        <div className="itemInfo">
          <header>
            <span className="item-title" style={{ fontWeight: "bold" }}>
              {item.name}
            </span>
            <div className="rate">
              <StarRating rating={parseFloat(item.rating)} />
              <span className="rating-text">({item.rating})</span>
              <a href="#review" className="review-link">
                <span className="review-count">
                  {item.reviewCount}개 상품평
                </span>
              </a>
              <span className="separator"> | </span>
              <span className="order-count">{item.saleCount}개 구매</span>
              <br />
              <div className="price-and-share">
                <div
                  style={{ color: "red", fontSize: "20px", fontWeight: "bold" }}
                >
                  {price}원
                </div>
                <div style={{ cursor: "pointer" }}>
                  <i className="share" onClick={() => setModalShow(true)}>
                    <img
                      src={share}
                      alt="공유"
                      width={20}
                      style={{ cursor: "pointer" }}
                    />
                  </i>
                  <ShareModal
                    show={modalShow}
                    onHide={() => setModalShow(false)}
                  />
                </div>
              </div>
            </div>
            <hr />
          </header>
          <div className="item-details">
            <p className="item-id">{item.id}</p>
            <span>
              <InfoText>
                <strong>원산지</strong>
                {/* {item.상태.원산지} */}
              </InfoText>
            </span>
            <br />
            <span>
              <InfoText>
                <strong className="label">무게</strong> 1t
              </InfoText>
            </span>
            <br />
            <span>
              <InfoText>
                <strong className="label">배송방법</strong> 로젠택배
              </InfoText>
            </span>
            <br />
            <span>
              <InfoText>
                <strong className="label">배송비</strong> 3500
              </InfoText>
            </span>
            <br />
            <span>
              <InfoText>
                <strong className="label">도서산간지역</strong> 8500원
              </InfoText>
            </span>
            <br />
          </div>
          <br />
          <div>
            <div
              style={{
                display: "flex",
                justifyContent: "center", // 가로 중앙 정렬
                alignItems: "center", // 세로 중앙 정렬
                width: "100%", // 전체 가로 폭 사용
                maxWidth: "400px", // 최대 크기 설정
                margin: "0 auto", // 양쪽 여백을 줘서 중앙 배치
              }}
            >
              <InputGroup style={{ marginRight: "10px", width: "150px" }}>
                <Button
                  variant="outline-secondary"
                  onClick={() => {
                    if (count > 1) {
                      setCount(count - 1)
                    }
                  }}
                >
                  -
                </Button>
                <Input
                  type={"number"}
                  style={{
                    textAlign: "center",
                    width: "60px", // 숫자 입력 필드의 크기 유지
                  }}
                  value={count}
                  onChange={handleCountChange}
                />
                <Button
                  variant="outline-secondary"
                  onClick={() => setCount(count + 1)}
                >
                  +
                </Button>
              </InputGroup>
              <div
                style={{
                  fontSize: "20px",
                  fontWeight: "bold",
                  marginLeft: "10px",
                }}
              >
                {(count * item.price).toLocaleString()}원
              </div>
            </div>
            <br />
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Button
              variant="outline-secondary"
              size="lg"
              style={{ marginRight: "5px", width: "50%" }}
              onClick={() => addShopping(item)}
            >
              <strong>장바구니</strong>
            </Button>
            <Button
              variant="primary"
              size="lg"
              style={{ marginLeft: "5px", width: "50%" }}
              onClick={handleBuy}
            >
              <strong>구매하기</strong>
            </Button>
          </div>
          <MoreOption options={options} item={item} />
        </div>
      </div>
      <div className="reviews-section">
        <RelativeProduct id={id} />
      </div>
      <ItemInfo item={item} />
      <ModalComponent
        title={"대량구매 관련 안내"}
        body={
          <>
            <div>20개 이상 대량 구매시 개별 연락 부탁드립니다.</div>
            <div>
              전화번호:{" "}
              <span style={{ color: "red", fontWeight: "bold" }}>
                010-0000-0000
              </span>
            </div>
          </>
        }
        show={isAlertModal}
        backdrop={true}
        centered={true}
        onConfirm={() => setIsAlertModal(false)}
      />
    </div>
  )
}

export default ItemPage
