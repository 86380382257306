import React, { useEffect, useState } from "react"
import { Button, Card, Form, Image, InputGroup } from "react-bootstrap"
import styled from "styled-components"
import { client } from "../../API/api"

const ChatContainer = styled(Card)`
  height: 100%; /* Full height of the container */
  display: flex;
  flex-direction: column;
`

const ChatBody = styled(Card.Body)`
  flex-grow: 1;
  overflow-y: auto;
`

const ChatFooter = styled(Card.Footer)`
  display: flex;
  justify-content: space-between;
`

const StyledImage = styled(Image)`
  width: 50px;
  height: 50px;
  margin-right: 10px;
  border-radius: 50%;
  object-fit: cover; /* 비율 유지 + 사진이 꽉 차도록 */
  object-position: center; /* 사진의 중앙 부분을 보여줌 */
`

const StyledInputGroup = styled(InputGroup)`
  margin-top: 10px;
  width: 100%;
  display: flex;
`

const StyledTextarea = styled(Form.Control).attrs({ as: "textarea" })`
  resize: none; /* Disable manual resizing */
  overflow: hidden;
  min-height: 38px; /* Initial height */
  max-height: 200px; /* Optional max height */
  transition: height 0.2s ease;
  flex-grow: 1; /* Allow the textarea to grow and fill available space */
`

const StyledForm = styled(Form)`
  width: 100%;
`

function ChatBoxForm({ selectedQnaId, isAdmin }) {
  const [reply, setReply] = useState("답변이 없습니다.")
  const [adminReply, setAdminReply] = useState("")
  const [isReplying, setIsReplying] = useState(false)
  // const userId = useRecoilValue(UId);
  const userId = localStorage.getItem("userId")

  useEffect(() => {
    const fetchReply = async () => {
      try {
        const response = await client.get(
          `/Comment/findCommentsByQnaId?qnaId=${selectedQnaId.id}`
        )

        console.log("API Response:", response)

        if (response.data.length === 0) {
          setReply({
            content: "답변이 없습니다.",
          })
        } else {
          const firstReply = response.data[0]
          setReply(firstReply)
        }
      } catch (error) {
        console.error(error)
      }
    }
    fetchReply()
    setIsReplying(false)
  }, [selectedQnaId])

  const handleAdminReplyChange = (e) => {
    const { value } = e.target
    setAdminReply(value)

    // Adjust the height of the textarea dynamically
    e.target.style.height = "auto"
    e.target.style.height = `${e.target.scrollHeight}px`
  }

  console.log("replyId", reply)
  const handleAdminReplySubmit = async () => {
    if (adminReply.trim() !== "") {
      const postData = {
        qnaId: selectedQnaId.id,
        userId,
        content: adminReply,
      }

      try {
        if (reply.content === "답변이 없습니다.") {
          // 새로 답변을 작성하는 경우
          await client.post(`/Comment/createComment`, postData)
        } else {
          // 기존 답변을 수정하는 경우
          await client.put(`/Comment/updateComment/${userId}`, postData)
        }

        window.location.reload()
      } catch (error) {
        console.error("Failed to submit reply:", error)
      }
    }
  }

  const handleReplyButtonClick = () => setIsReplying(true)

  const handleEditButtonClick = () => {
    setIsReplying(true)
    setAdminReply(reply.content)
  }

  return (
    <ChatContainer>
      {selectedQnaId !== null ? (
        <>
          <Card.Header className="d-flex align-items-center">
            <StyledImage src={selectedQnaId.productUrl} alt="profile" />
            {selectedQnaId.productName}
          </Card.Header>
          <ChatBody>
            <p>
              <strong>문의 내용:</strong>
            </p>
            <p style={{ whiteSpace: "pre-wrap" }}>{selectedQnaId.title}</p>
            <p>{new Date(selectedQnaId.date).toLocaleDateString()}</p>
            <hr />
            <p>
              <strong>원정푸드 관리자</strong>
            </p>
            {isReplying ? (
              <StyledForm>
                <StyledInputGroup>
                  <StyledTextarea
                    rows={3}
                    placeholder="답변을 입력하세요"
                    value={adminReply}
                    onChange={handleAdminReplyChange}
                  />
                </StyledInputGroup>
              </StyledForm>
            ) : (
              <>
                <p style={{ whiteSpace: "pre-wrap" }}>{reply.content}</p>
                {/* 수정하기,답변 하기 클릭시 */}
                {reply.date && (
                  <p>{new Date(reply.date).toLocaleDateString()}</p>
                )}
              </>
            )}
          </ChatBody>
          {isAdmin && (
            <ChatFooter>
              {!isReplying ? (
                reply.content === "답변이 없습니다." ? (
                  <Button
                    variant="primary"
                    onClick={handleReplyButtonClick}
                    style={{ display: "flex", marginLeft: "auto" }}
                  >
                    답변하기
                  </Button>
                ) : (
                  <Button
                    variant="warning"
                    onClick={handleEditButtonClick}
                    style={{ display: "flex", marginLeft: "auto" }}
                  >
                    수정하기
                  </Button>
                )
              ) : (
                <Button
                  variant="primary"
                  onClick={handleAdminReplySubmit}
                  style={{ display: "flex", marginLeft: "auto" }}
                >
                  완료
                </Button>
              )}
            </ChatFooter>
          )}
        </>
      ) : (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "20px",
            height: "750px",
          }}
        >
          문의글을 선택해주세요.
        </div>
      )}
    </ChatContainer>
  )
}

export default ChatBoxForm
