import React from "react"

const ShippingInfo = () => {
  return (
    <div style={styles.container}>
      <h2 style={styles.header}>📦 배송 정보</h2>
      <table style={styles.table}>
        <tbody>
          <tr>
            <th>항목</th>
            <th>내용</th>
          </tr>
          <tr>
            <td>
              <strong>배송 방법</strong>
            </td>
            <td>🚚 순차 배송</td>
          </tr>
          <tr>
            <td>
              <strong>배송비</strong>
            </td>
            <td>
              ✅ 무료배송 <br />- 로켓배송 상품 중{" "}
              <strong>19,800원 이상</strong> 구매 시 무료배송 <br />- 도서산간
              지역 추가비용 없음
            </td>
          </tr>
          <tr>
            <td>
              <strong>묶음 배송</strong>
            </td>
            <td>✅ 가능</td>
          </tr>
          <tr>
            <td>
              <strong>배송 기간</strong>
            </td>
            <td>
              ⏳ <strong>친구 배송 지역</strong>: 주문 및 결제 완료 후{" "}
              <strong>1~2일 이내 도착</strong> <br />⏳{" "}
              <strong>친구 미배송 지역</strong>: 주문 및 결제 완료 후{" "}
              <strong>2~3일 이내 도착</strong> <br />
              📌 도서 산간 지역은 하루 추가 소요될 수 있습니다.
            </td>
          </tr>
        </tbody>
      </table>

      <p style={styles.note}>
        🔹 천재지변, 물량 수급 변동 등의 예외적인 사유 발생 시, 배송이 다소
        지연될 수 있습니다.
      </p>
    </div>
  )
}

// 스타일 객체
const styles = {
  container: {
    fontFamily: "Arial, sans-serif",
    padding: "20px",
    lineHeight: "1.6",
  },
  header: {
    backgroundColor: "#357ddc",
    color: "white",
    padding: "10px",
    borderRadius: "5px",
  },
  table: { width: "100%", borderCollapse: "collapse", margin: "15px 0" },
  note: { fontSize: "14px", color: "#555" },
}

export default ShippingInfo
