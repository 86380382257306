import React, { useEffect, useRef, useState } from "react"
import Nav from "react-bootstrap/Nav"
import { client } from "../../API/api"
import { useParams } from "react-router-dom"
import "./ItemInfo.css"
import DetailInfoComponent from "./ItemInfo/DetailInfoComponent"
import ReviewInfoComponent from "./ItemInfo/ReviewInfoComponent"
import QnaInfoComponent from "./ItemInfo/QnaInfoComponent"
import ReturnPolicy from "./ShippingPolicyInfo/ReturnPolicy.js"
import ShippingInfo from "./ShippingPolicyInfo/ShippingInfo"

export default function ItemInfo({ item }) {
  const { id } = useParams()
  const [review, setReview] = useState([])
  const [qna, setQna] = useState([])
  const [qnaPage, setQnaPage] = useState(0)
  const [loadId, setLoadId] = useState(1)
  const [isSticky, setIsSticky] = useState(false) // Sticky 상태 관리
  const navRef = useRef(null) // Sticky 요소 참조
  const itemInfoRef = useRef(null) // ItemInfo 영역 참조
  const [selectedMenu, setSelectedMenu] = useState("상세 정보")
  const [reviewPage, setReviewPage] = useState(0)

  // Review 데이터 가져오기
  useEffect(() => {
    const fetchAndSetData = async () => {
      const response = await client.get(
        `/Review/findReviewsByProductId?page=${reviewPage}&size=10&productId=${id}`
      )
      setReview(response.data)
    }
    fetchAndSetData()
  }, [reviewPage, setReviewPage, id])

  // 이름 마스킹 함수
  const maskName = (name) => {
    if (name.length === 1) {
      return "*"
    } else if (name.length === 2) {
      return `${name.charAt(0)}*`
    } else {
      const firstChar = name.charAt(0)
      const lastChar = name.charAt(name.length - 1)
      const maskedChars = "*".repeat(name.length - 2)
      return `${firstChar}${maskedChars}${lastChar}`
    }
  }

  // QnA 데이터 가져오기
  useEffect(() => {
    const fetchAndSetQnaData = async () => {
      const response = await client.get(
        `/Qna/getAllQnaByProductId?page=${qnaPage}&size=10&productId=${id}`
      )
      setQna(response.data)
    }
    fetchAndSetQnaData()
  }, [qnaPage, id])

  // 날짜 포맷팅 함수
  const formatDate = (dateString) => {
    const date = new Date(dateString)
    const year = date.getFullYear()
    const month = String(date.getMonth() + 1).padStart(2, "0")
    const day = String(date.getDate()).padStart(2, "0")
    const hours = String(date.getHours()).padStart(2, "0")
    const minutes = String(date.getMinutes()).padStart(2, "0")
    return `${year}.${month}.${day} ${hours}:${minutes}`
  }

  // 스크롤 이벤트와 IntersectionObserver로 sticky 상태 제어
  useEffect(() => {
    const handleScroll = () => {
      const itemInfoRect = itemInfoRef.current.getBoundingClientRect() // ItemInfo의 위치 정보

      // ItemInfo가 화면에서 보이지 않고 스크롤보다 위에 있는지 확인
      if (itemInfoRect.bottom < 0) {
        setIsSticky(true) // 화면에서 보이지 않고 위에 있으면 sticky
      } else {
        setIsSticky(false) // 보이면 sticky 해제
      }
    }

    // 스크롤 이벤트 리스너 추가
    window.addEventListener("scroll", handleScroll)

    return () => {
      window.removeEventListener("scroll", handleScroll) // 컴포넌트 언마운트 시 리스너 제거
    }
  }, [])

  const infoLoad = () => {
    if (loadId === 1) {
      return <DetailInfoComponent item={item} />
    }
    if (loadId === 2) {
      return (
        <ReviewInfoComponent
          review={review}
          setReview={setReview}
          maskName={maskName}
          formatDate={formatDate}
          id={id}
          reviewPage={reviewPage}
          setReviewPage={setReviewPage}
          productObject={item}
        />
      )
    }
    if (loadId === 3) {
      return (
        <QnaInfoComponent
          qna={qna}
          maskName={maskName}
          formatDate={formatDate}
          qnaPage={qnaPage}
          setQnaPage={setQnaPage}
        />
      )
    }
    if (loadId === 4) {
      return (
        <>
          <ShippingInfo />
          <ReturnPolicy />
        </>
      )
    }
  }

  return (
    <div style={{ marginTop: "80px" }}>
      <div ref={itemInfoRef}>
        {/* Sticky 상태가 적용되는 메뉴 */}
        <div
          id="sticky-nav"
          ref={navRef}
          style={{ zIndex: "2" }}
          className={`nav-container ${isSticky ? "sticky" : ""}`}
        >
          <Nav
            justify
            variant="tabs"
            className={"nav"}
            activeKey={selectedMenu}
          >
            <Nav.Item onClick={() => setLoadId(1)}>
              <Nav.Link
                eventKey={"상세 정보"}
                onClick={() => {
                  setSelectedMenu("상세 정보")
                }}
              >
                <span style={{ fontSize: "14px", color: "black" }}>
                  상세 정보
                </span>
              </Nav.Link>
            </Nav.Item>
            <Nav.Item onClick={() => setLoadId(2)}>
              <Nav.Link
                eventKey={"구매평"}
                onClick={() => {
                  setSelectedMenu("구매평")
                }}
              >
                <span style={{ fontSize: "14px", color: "black" }}>
                  구매평 ({review.size})
                </span>
              </Nav.Link>
            </Nav.Item>
            <Nav.Item onClick={() => setLoadId(3)}>
              <Nav.Link
                eventKey={"QnA"}
                onClick={() => {
                  setSelectedMenu("QnA")
                }}
              >
                <span style={{ fontSize: "14px", color: "black" }}>
                  QnA ({qna.size})
                </span>
              </Nav.Link>
            </Nav.Item>
            <Nav.Item onClick={() => setLoadId(4)}>
              <Nav.Link
                eventKey={"안내"}
                onClick={() => {
                  setSelectedMenu("안내")
                }}
              >
                <span style={{ fontSize: "14px", color: "black" }}>
                  배송/교환/반품 안내
                </span>
              </Nav.Link>
            </Nav.Item>
          </Nav>
        </div>
      </div>
      <div
        style={{
          textAlign: "center",
          paddingTop: "40px",
          paddingBottom: "40px",
        }}
      >
        {infoLoad()}
      </div>
    </div>
  )
}
