import React, { useState } from "react"
import { useNavigate } from "react-router-dom"
import { useRecoilState, useRecoilValue } from "recoil"
import { itemId, login, shoppingCart } from "../../../App"
import { OverlayTrigger, Tooltip } from "react-bootstrap"
import cart from "./Img/addCart.png"
import "../../../CSS/ConBox/Item/ItemNode.css"
import StarRating from "./StarRating"
import { client } from "../../API/api"

const ItemNode = ({ item }) => {
  const navi = useNavigate()
  const [it, setIt] = useRecoilState(itemId)
  const [shopping, setShopping] = useRecoilState(shoppingCart)
  const [showTooltip, setShowTooltip] = useState(false)
  const isLogin = useRecoilValue(login)
  const userId = localStorage.getItem("userId")
  //이미 장바구니에 추가 된 경우
  const [isAlready, setIsAlready] = useState(false)
  const setLocalStorageItem = (key, value) =>
    localStorage.setItem(key, JSON.stringify(value))
  // console.log("item",item)

  // todo: 카트 상품 추가시 id가 바뀌어서 들어감(백엔드에서 해결해줘야함 :원래 그렇다함.)
  const addShoopingServer = async (productId) => {
    try {
      await client.put("/Cart/createCarts", [
        { productId, userId: localStorage.getItem("userId"), quantity: 1 },
      ])
    } catch (error) {
      console.error(error.response.data.message)
    }
  }
  // 변경이 있을때마다 서버에서 가져오기
  const getShoopingServer = async () => {
    // 서버의 장바구니 데이터 가져오기
    const cartResponse = await client.get(`/Cart/findAllCart?userId=${userId}`)
    const serverCartData = cartResponse.data.carts
    localStorage.setItem("shoppingCart", JSON.stringify(serverCartData))
    setShopping(serverCartData)
  }

  const addShopping = async (nowitem, event) => {
    // 이벤트 전파를 막아서 이미지 클릭 이벤트가 발생하지 않도록 합니다.
    event.stopPropagation()

    const itemIndex = shopping.findIndex((item) => item.id === nowitem.id)

    if (itemIndex !== -1) {
      //이미 존재 할 경우 이미 장바구니에 존재한다고 알림
      setIsAlready(true)
    } else {
      //로그인 안했을 경우
      if (!isLogin) {
        const newList = [
          ...shopping,
          {
            id: item.id,
            productName: item.name,
            productImageUrl: item.photoUrl,
            price: item.price,
            quantity: 1,
          },
        ]
        setLocalStorageItem("shoppingCart", newList)
        setShopping(newList)
      } else {
        // 서버에 추가
        await addShoopingServer(nowitem.id)
        await getShoopingServer()
      }
    }

    // Show tooltip
    setShowTooltip(true)
    // Hide tooltip after 2 seconds
    setTimeout(() => setShowTooltip(false), 2000)
  }

  const additional = () => {
    const today = new Date()
    const itemUploadTime = new Date(item.date)
    let returnCompo = []
    if (today - itemUploadTime <= 7 * 24 * 60 * 60 * 1000)
      returnCompo.push(
        <div key="tag new-tag" className="tag new-tag">
          NEW
        </div>
      )
    if (item.saleCount >= 10000)
      returnCompo.push(
        <div key="tag hot-tag" className="tag hot-tag">
          HOT
        </div>
      )
    return <div className="tag-container">{returnCompo}</div>
  }

  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      {isAlready
        ? "이미 장바구니에 추가된 상품입니다."
        : `${item.name}이(가) 장바구니에 추가되었습니다.`}
    </Tooltip>
  )

  return (
    <div
      className="item"
      onMouseEnter={() => setIt(item)}
      onClick={() => navi(`/Item/${item.id}`)} // 이미지 클릭 시 네비게이션 수행
    >
      <div className="image">
        <div className="img-container">
          <img src={item.photoUrl} alt="사진" className="item-img" />
          <div className="cart-button-container">
            <OverlayTrigger
              placement="top"
              show={showTooltip}
              overlay={renderTooltip}
            >
              <button
                className="nodeBtn"
                onClick={(event) => addShopping(item, event)}
              >
                <img
                  src={cart}
                  alt="장바구니"
                  className="icon-small"
                  style={{ width: "25px", height: "25px", zIndex: 10 }}
                />
              </button>
            </OverlayTrigger>
          </div>
        </div>
      </div>
      <div className="info">
        <div className="content" onClick={() => navi(`/Item/${item.id}`)}>
          <p className="title" style={{ fontSize: "18px" }}>
            {item.name}
          </p>
          <div className="price" style={{ fontSize: "16px" }}>
            {item.price.toLocaleString()}원
          </div>
          {additional()}
          <div className="rating">
            <StarRating rating={parseFloat(item.rating)} />
            <span className="rating-text"> ({item.reviewCount})</span>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ItemNode
