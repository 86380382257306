import React, { useEffect, useState } from "react"

import { Button, Input } from "reactstrap"
import {
  deleteObject,
  getDownloadURL,
  getStorage,
  ref,
  uploadBytes,
} from "firebase/storage"
import imageCompression from "browser-image-compression"
import { storage } from "../../../firebase"
import { client } from "../../API/api"
import Slider from "react-slick"
import styled from "styled-components"
import MainPopup from "../../ConBox/FirstPage/MainPopup"

export default function AnnouncementPopUp() {
  const [image, setImage] = useState(null)
  const [previewUrl, setPreviewUrl] = useState(null)
  const [uploading, setUploading] = useState(false)
  const [defaultPhoto, setDefaultPhoto] = useState([])
  const [update, setUpdate] = useState(false)
  const [showMainPop, setShowMainPop] = useState(false)
  const handleUpdate = () => {
    setUpdate(!update)
  }
  useEffect(() => {
    const getData = async () => {
      try {
        const response = await client.get("/Popup/findAllPopup")
        setDefaultPhoto(response.data)
      } catch (error) {
        console.error("Error fetching data:", error)
      }
    }
    getData()
  }, [update])
  console.log("24preview", previewUrl)
  // 이미지 변경 핸들러
  const handleImageChange = async (e) => {
    const file = e.target.files[0]

    if (file && file.name) {
      const fileExtension = file.name.split(".").pop().toLowerCase()

      if (fileExtension === "webp") {
        setImage(file)
        setPreviewUrl(URL.createObjectURL(file))
      } else {
        try {
          const options = {
            maxSizeMB: 2,
            maxWidthOrHeight: 1920,
            useWebWorker: true,
            fileType: "image/webp",
          }
          const compressedFile = await imageCompression(file, options)
          const newFileName =
            file.name.split(".").slice(0, -1).join(".") + ".webp"
          const webpFile = new File([compressedFile], newFileName, {
            type: "image/webp",
          })

          setImage(webpFile)
          setPreviewUrl(URL.createObjectURL(webpFile))
        } catch (error) {
          console.error("Image compression failed:", error)
        }
      }
    } else {
      console.error("No file selected or file name is undefined.")
    }
  }

  // 이미지 업로드 핸들러
  const handleUpload = async () => {
    if (!image) {
      alert("이미지를 선택해주세요.")
      return
    }

    setUploading(true)
    const timestamp = new Date().getTime()
    const fileExtension = image.name.split(".").pop()
    const newFileName = `${image.name.split(".").slice(0, -1).join(".")}_${timestamp}.${fileExtension}`
    // Firebase Storage 참조 생성

    const storageRef = ref(
      storage,
      `announcements/${encodeURIComponent(newFileName)}`
    )

    try {
      // Firebase에 이미지 업로드
      await uploadBytes(storageRef, image)
      const downloadURL = await getDownloadURL(storageRef)

      // 서버에 이미지 URL 저장
      await client.post("/Popup/createPopup", { photoUrl: downloadURL })
      alert("공지사항이 업로드되었습니다!")
    } catch (error) {
      console.error("Upload failed:", error)
      alert("업로드에 실패했습니다.")
    } finally {
      handleUpdate()
      setUploading(false)
    }
  }

  // url로 firebase 경로 찾아서 삭제
  const extractPath = (url) => {
    const regex = /\/o\/(.*?)\?/
    const match = url.match(regex)
    if (match && match[1]) {
      return decodeURIComponent(match[1])
    }
    return null
  }
  // 이미지 삭제 핸들러
  const handleDelete = async (id, index) => {
    setUploading(true)

    try {
      const storage = getStorage()
      const photoPath = extractPath(defaultPhoto[index].photoUrl)
      const imageRef = ref(storage, photoPath)
      await deleteObject(imageRef)
      await client.delete(`/Popup/deletePopup?id=${id}`)
      // window.location.reload();
      alert("팝업이 성공적으로 삭제되었습니다.")
      // Firebase에서 이미지 삭제
    } catch (error) {
      alert("오류발생")
      console.error("Delete failed:", error)
    }
    handleUpdate()
    setUploading(false)
  }

  const SampleNextArrow = ({ onClick }) => (
    <ArrowButton onClick={onClick} style={{ right: "10px" }}>
      &gt;
    </ArrowButton>
  )

  const SamplePrevArrow = ({ onClick }) => (
    <ArrowButton onClick={onClick} style={{ left: "10px" }}>
      &lt;
    </ArrowButton>
  )
  //이미지 슬라이드
  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    autoplay: false,
    centerPadding: "60px",
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    slidesToShow: 4,
    dotsClass: "dots_custom",
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  }
  return (
    <div className="container">
      <h2>공지사항 팝업 관리</h2>
      <p style={{ marginTop: "20px", marginBottom: "10px" }}>공지사항 이미지</p>

      <Input
        type="file"
        onChange={handleImageChange}
        style={{ marginBottom: "10px" }}
      />
      {/*<CKEditorComponent />*/}
      <div style={{ display: "flex", marginBottom: "10px" }}>
        {previewUrl && (
          <Button
            onClick={() => setShowMainPop(true)}
            style={{ display: "block", marginTop: "20px", marginRight: "10px" }}
          >
            미리 보기
          </Button>
        )}
        <Button
          onClick={handleUpload}
          disabled={uploading}
          style={{ display: "block", marginTop: "20px" }}
        >
          {uploading ? "업로드 중..." : "업로드"}
        </Button>
      </div>
      {defaultPhoto.length > 0 && defaultPhoto[0].photoUrl ? (
        <div>
          <div style={{ marginTop: "40px" }}>
            <h2> 기존 공지사항</h2>
          </div>
          <Slider {...settings}>
            {defaultPhoto.map((item, index) => (
              <div style={{ width: "500px" }}>
                <Button
                  onClick={() => handleDelete(item.id, index)}
                  disabled={uploading}
                  style={{
                    display: "block",
                    marginTop: "10px",
                    marginBottom: "10px",
                  }}
                >
                  {uploading ? "삭제 중..." : "이미지 삭제"}
                </Button>
                <img src={item.photoUrl} alt={"공지사항"} width="300px" />
              </div>
            ))}
          </Slider>
          {/*<img src={defaultPhoto[0].photoUrl} alt="기존 공지사항" />*/}
        </div>
      ) : (
        <p>기존 공지사항 이미지가 없습니다.</p>
      )}
      {showMainPop && (
        <MainPopup
          setShowMainPop={setShowMainPop}
          popupUrl={[{ id: -1, photoUrl: previewUrl }]}
          isAdmin={true}
        />
      )}
    </div>
  )
}

const ArrowButton = styled.div`
  position: absolute; /* 슬라이더 위로 이동 */
  top: 50%;
  transform: translateY(-50%);
  z-index: 5;
  cursor: pointer;
  width: 50px;
  height: 50px;
  background: gray;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  &:hover {
    background: darkgray;
  }
`
