import React, { useEffect, useState } from "react"
import styled from "styled-components"
import { Button, CloseButton, Container, Image } from "react-bootstrap"
import { DashLg, PlusLg } from "react-bootstrap-icons"
import { client } from "../../API/api"

const CartItemBox = styled(Container)`
  border: 1px solid #ddd;
  border-radius: 10px;
  margin-bottom: 10px;
  background-color: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);

  @media (max-width: 480px) {
    padding: 5px;
  }
`

const CartItemWrapper = styled.div`
  display: flex;
  padding: 15px;
  justify-content: space-between;
  align-items: center;
  position: relative;

  @media (max-width: 480px) {
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    padding: 10px;
  }
`

const ItemImage = styled(Image)`
  width: 80px;
  height: 80px;
  display: flex;
  align-items: center;
  object-fit: cover;
  border-radius: 10px;

  @media (max-width: 480px) {
    width: 60px;
    height: 60px;
    margin-right: 10px;
  }
`

const ItemDetails = styled.div`
  flex-grow: 1;
  margin-left: 10px;
  display: flex;
  flex-direction: column;

  h5 {
    margin: 0;
    font-size: 16px;
    color: #333;

    @media (max-width: 480px) {
      font-size: 14px;
    }
  }

  p {
    margin: 5px 0 0 0;
    font-size: 14px;
    color: #888;
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media (max-width: 480px) {
      font-size: 12px;
    }
  }
`

const QuantityWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-top: 5px;

  button {
    padding: 2px 6px;
  }
`

const Price = styled.div`
  font-size: 16px;
  font-weight: 700;
  color: #333;
  margin-left: 10px;

  @media (max-width: 480px) {
    font-size: 16px;
  }
`

const CustomCheckboxWrapper = styled.div`
  display: flex;
  margin-right: 5px;
  align-items: center;
`

const CustomCheckboxLabel = styled.label`
  display: flex;
  align-items: center;
  cursor: pointer;
  position: relative;
`

const HiddenCheckbox = styled.input.attrs({ type: "checkbox" })`
  display: none;
`

const StyledCheckbox = styled.div`
  width: 24px;
  height: 24px;
  background: ${(props) => (props.checked ? "#007bff" : "#fff")};
  border: 2px solid ${(props) => (props.checked ? "#007bff" : "#ccc")};
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s;

  svg {
    visibility: ${(props) => (props.checked ? "visible" : "hidden")};
  }
`

const CheckIcon = styled.div`
  color: ${(props) => (props.checked ? "#fff" : "#ccc")};
  font-size: 16px;
  visibility: visible;
`

const CartItem = ({ item, list, setList, checked, onChange }) => {
  const [isChecked, setIsChecked] = useState(checked)
  console.log("0203개발", item)
  useEffect(() => setIsChecked(checked), [checked])

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked)
    onChange(item.id, !isChecked)
  }
  //서버랑 통신
  const updateQuantity = async (itemID, quantity) => {
    const itemQuantity = Number(quantity)
    console.log("itemID:", itemID, "itemQuantity:", itemQuantity)
    try {
      await client.put(`/Cart/updateQuantityCart`, {
        cartId: itemID.trim(),
        quantity: itemQuantity,
      })
    } catch (error) {
      console.error(error.response.data.message)
    }
  }
  // 단일 카트 서버 삭제
  const deleteServerItem = async (itemId) => {
    try {
      //단일 삭제가 없음 보류
      await client.delete(`/Cart/deleteCart?cartId=${itemId}`)
    } catch (error) {
      console.error(error.response.data.message)
    }
  }
  // 단일 카트 서버 삭제
  const deleteItem = (itemID) => {
    const updatedList = list.filter((item) => itemID !== item.id)
    setList(updatedList)
    localStorage.setItem("shoppingCart", JSON.stringify(updatedList))
    deleteServerItem(itemID)
  }

  const reduceQuantity = (itemID) => {
    const updatedList = list.map((item) => {
      if (itemID === item.id && item.quantity > 1) {
        // 새로 수정된 객체 반환
        updateQuantity(item.id, item.quantity - 1)
        return { ...item, quantity: item.quantity - 1 }
      }
      return item
    })

    setList(updatedList)
    localStorage.setItem("shoppingCart", JSON.stringify(updatedList))
  }

  const addQuantity = (itemID) => {
    const updatedList = list.map((item) => {
      if (itemID === item.id) {
        // 새로 수정된 객체 반환
        updateQuantity(item.id, item.quantity + 1)
        return { ...item, quantity: item.quantity + 1 }
      }
      return item
    })
    setList(updatedList)
    localStorage.setItem("shoppingCart", JSON.stringify(updatedList))
  }

  return (
    <CartItemBox>
      <CartItemWrapper>
        <CustomCheckboxWrapper>
          <CustomCheckboxLabel>
            <HiddenCheckbox
              checked={isChecked}
              onChange={handleCheckboxChange}
            />
            <StyledCheckbox checked={isChecked}>
              <CheckIcon checked={isChecked}>✓</CheckIcon>
            </StyledCheckbox>
          </CustomCheckboxLabel>
        </CustomCheckboxWrapper>

        <ItemImage src={item.productImageUrl} alt={item.productName} />
        <ItemDetails>
          <h5
            style={{
              fontSize: "px",
              fontWeight: "700",
              whiteSpace: "pre-line",
              paddingRight: "10px",
            }}
          >
            {item.productName}
          </h5>
          <QuantityWrapper>
            <Button
              size="sm"
              variant="outline-primary"
              onClick={() => reduceQuantity(item.id)}
            >
              <DashLg />
            </Button>
            <span style={{ margin: "0 8px" }}>{item.quantity}</span>
            <Button
              size="sm"
              variant="outline-primary"
              onClick={() => addQuantity(item.id)}
            >
              <PlusLg />
            </Button>
            <Price>{(item.price * item.quantity).toLocaleString()}원</Price>
          </QuantityWrapper>
        </ItemDetails>
        <CloseButton
          onClick={() => deleteItem(item.id)}
          style={{
            position: "absolute",
            top: "10px",
            right: "0px",
            cursor: "pointer",
          }}
        />
      </CartItemWrapper>
    </CartItemBox>
  )
}

export default CartItem
