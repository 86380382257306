import React from "react"
import { Nav, NavDropdown } from "react-bootstrap"
import { useNavigate } from "react-router-dom"
import styled from "styled-components"
import { mobile } from "../../../App"
import { useRecoilValue } from "recoil"

const ScrollContainer = styled.div`
  @media (max-width: 480px) {
    display: flex;
    width: 100%;
    justify-content: center; /* 중앙 정렬 */
    align-items: center;
    -webkit-overflow-scrolling: touch;
    padding-bottom: 5px; /* 스크롤바를 더 위로 이동 */
    white-space: nowrap; /* 콘텐츠가 한 줄로 유지되도록 설정 */
    padding-right: 10px;
    padding-left: 10px; /* 왼쪽 여백 추가 */
    z-index: 100;

    /* 스크롤바 스타일 */
    &::-webkit-scrollbar {
      height: 4px; /* 스크롤바의 높이를 더 키움 */
    }

    &::-webkit-scrollbar-track {
      background: transparent; /* 스크롤바의 배경 */
    }

    &::-webkit-scrollbar-thumb {
      background: rgba(0, 0, 0, 0.3); /* 스크롤바의 색상 */
      border-radius: 10px; /* 스크롤바의 둥근 모서리 */
    }

    &::-webkit-scrollbar-thumb:hover {
      background: rgba(0, 0, 0, 0.5); /* 스크롤바의 색상 (hover 시) */
    }
  }
`

const ScrollableNav = styled(Nav)`
  display: flex;
  justify-content: flex-start; /* 왼쪽 정렬 */
  width: 100%;
  flex-wrap: nowrap; /* 내용이 한 줄로 유지되도록 설정 */
  //overflow-x: auto; /* 스크롤 가능하도록 설정 */
  scroll-padding-left: 10px; /* 왼쪽 여백 추가 */
  scroll-padding-right: 10px; /* 오른쪽 여백 추가 */

  @media (max-width: 480px) {
    width: 100vw;
    flex-wrap: nowrap;
    justify-content: flex-start; /* 왼쪽 정렬 */
    overflow-x: scroll; /* 스크롤 가능하도록 설정 */
  }
`

const NavItemStyled = styled(Nav.Link)`
  padding-left: 10px;
  padding-right: 10px;
  white-space: nowrap; /* 줄바꿈 방지 */
`

export default function HeaderMenu({
  selectCate,
  categoryMenu,
  naviLinks,
  setSelectCate,
  handleNavClick,
}) {
  const navi = useNavigate()
  const isMobile = useRecoilValue(mobile)

  return (
    <ScrollContainer className="navbar-custom" style={{ alignItems: "center" }}>
      <ScrollableNav variant="underline" activeKey={selectCate}>
        {categoryMenu.length !== 0 &&
          categoryMenu.map((item) => (
            <Nav.Link
              key={item.id}
              eventKey={item.categoryName}
              onClick={() => {
                navi(`/Category/${item.id}`)
                setSelectCate(item.categoryName)
              }}
              style={{
                paddingLeft: "10px",
                paddingRight: "10px",
              }}
            >
              <span style={{ fontSize: "16px" }}>{item.categoryName}</span>
            </Nav.Link>
          ))}
        {naviLinks.map((item, idx) =>
          item.label === "Company" ? (
            isMobile ? (
              <Nav.Link
                key={idx}
                eventKey="회사 소개"
                onClick={() => {
                  navi("/Company/Intro")
                  setSelectCate("회사 소개")
                }}
                style={{
                  paddingLeft: "5px",
                  paddingRight: "5px",
                }}
              >
                <span style={{ fontSize: "16px" }}> 회사 소개</span>
              </Nav.Link>
            ) : (
              <Nav.Item
                key={idx}
                style={{
                  zIndex: "10",
                  paddingLeft: "10px",
                  paddingRight: "10px",
                }}
              >
                <NavDropdown
                  title="회사 소개"
                  className="nav-link-company"
                  key={item.id}
                  eventKey="회사 소개"
                >
                  <NavDropdown.Item
                    eventKey="회사 소개"
                    onClick={() =>
                      handleNavClick("/Company/Intro", "회사 소개")
                    }
                  >
                    회사 소개
                  </NavDropdown.Item>
                  <NavDropdown.Item
                    eventKey="인증 목록"
                    onClick={() => handleNavClick("/Company/Cert", "회사 소개")}
                  >
                    인증 목록
                  </NavDropdown.Item>
                  <NavDropdown.Item
                    eventKey="품질 관리"
                    onClick={() =>
                      handleNavClick("/Company/Quality", "회사 소개")
                    }
                  >
                    품질 관리
                  </NavDropdown.Item>
                </NavDropdown>
              </Nav.Item>
            )
          ) : (
            <Nav.Link
              key={item.label}
              eventKey={item.text}
              onClick={() => handleNavClick(item.link, item.text)}
              style={{
                paddingLeft: "10px",
                paddingRight: "10px",
              }}
            >
              <span style={{ fontSize: "16px" }}>{item.text}</span>
            </Nav.Link>
          )
        )}
      </ScrollableNav>
    </ScrollContainer>
  )
}
