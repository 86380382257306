import React, { useState } from "react"
import { Button, Card, Image, ListGroup } from "react-bootstrap"
import styled from "styled-components"
import { client } from "../../API/api"

const StyledCard = styled(Card)`
  height: ${(props) => (props.isDashBoard ? "518px" : "770px")};
`

const StyledListGroup = styled(ListGroup)`
  flex-grow: 1;
  overflow-y: auto;
`

const StyledListItem = styled(ListGroup.Item)`
  display: flex;
  align-items: center;
  padding: 10px;
`

const StyledImage = styled(Image)`
  width: 50px;
  height: 50px;
  margin-right: 10px;
  border-radius: 50%;
  object-fit: cover; /* 비율 유지 + 사진이 꽉 차도록 */
  object-position: center; /* 사진의 중앙 부분을 보여줌 */
`

const ItemContent = styled.div`
  flex-grow: 1;
  overflow-x: hidden;
`

const Title = styled.div`
  font-weight: bold;
`

const DateText = styled.div`
  color: grey;
  font-size: 0.9em;
`

const StatusText = styled.div`
  font-size: 0.9em;
  color: ${(props) => (props.solved ? "green" : "red")};
`

function QnaListForm({
  setSelectedQnaId,
  qnaData = { list: [] },
  isDashBoard = false,
}) {
  const [editState, setEditState] = useState(false)

  const truncate = (text, maxLength) => {
    if (text.length > maxLength) {
      return text.substring(0, maxLength) + "..."
    }
    return text
  }

  const handleDelete = async (e, id) => {
    e.stopPropagation() // 이벤트 버블링을 막아 상위 요소로의 전파를 차단합니다.
    try {
      await client.delete(`/Qna/deleteQna?id=${id}`)
      window.location.reload()
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <StyledCard isDashBoard={isDashBoard}>
      {!isDashBoard && (
        <Card.Header style={{ display: "flex" }}>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            최근 문의 목록
          </div>
          <Button
            style={{ display: "flex", marginLeft: "auto" }}
            size="sm"
            onClick={() => setEditState(!editState)}
          >
            {editState ? <>완료</> : <>편집</>}
          </Button>
        </Card.Header>
      )}
      <StyledListGroup variant="flush">
        {qnaData.list.map((item) => (
          <StyledListItem
            key={item.id}
            style={{ cursor: "pointer" }}
            onClick={() => setSelectedQnaId(item)}
          >
            <StyledImage src={item.productUrl} alt="profile" />
            <ItemContent>
              <Title>{truncate(item.productName, 14)}</Title>
              <div>{truncate(item.title, 18)}</div>
              <DateText>{new Date(item.date).toLocaleDateString()}</DateText>
            </ItemContent>

            <StatusText solved={item.solvedFlag}>
              {editState ? (
                !item.solvedFlag ? (
                  <Button
                    variant="link"
                    onClick={(e) => {
                      handleDelete(e, item.id) // e를 인자로 전달합니다.
                    }}
                  >
                    삭제
                  </Button>
                ) : (
                  <></>
                )
              ) : item.solvedFlag ? (
                "답변완료"
              ) : (
                "미답변"
              )}
            </StatusText>
          </StyledListItem>
        ))}
      </StyledListGroup>
    </StyledCard>
  )
}

export default QnaListForm
