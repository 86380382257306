import React, { useEffect, useState } from "react"
import styled from "styled-components"
import { Button, Container } from "react-bootstrap"
import CartItem from "../CartItem"
import { useRecoilState, useRecoilValue } from "recoil"
import { deliveryFee, shoppingCart } from "../../../../App"
import { useNavigate } from "react-router-dom"

const ShoppingCartWrapper = styled(Container)`
  margin-top: 20px;
  display: grid;
  min-height: 70vh;
  grid-template-columns: 2fr 1fr;
  grid-gap: 20px;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`

const SummaryWrapper = styled.div`
  border: 1px solid #ddd;
  padding: 20px;
  border-radius: 10px;
  background-color: #f9f9f9;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);

  @media (max-width: 768px) {
    margin-top: 20px;
  }
`

const SummaryTitle = styled.h4`
  font-size: 22px;
  font-weight: bold;
  margin-bottom: 20px;
`

const TotalPrice = styled.h3`
  font-size: 24px;
  color: #ff6347;
  margin-top: 20px;
`

const CheckoutButton = styled(Button)`
  width: 100%;
  margin-top: 20px;
  background-color: #ff6347;
  border: none;

  &:hover {
    background-color: #ff4500;
  }
`

const CustomCheckboxWrapper = styled.div`
  display: flex;
  align-items: center;
`

const CustomCheckboxLabel = styled.label`
  display: flex;
  align-items: center;
  cursor: pointer;
  position: relative;
`

const HiddenCheckbox = styled.input.attrs({ type: "checkbox" })`
  display: none;
`

const StyledCheckbox = styled.div`
  width: 24px;
  height: 24px;
  background: ${(props) => (props.checked ? "#007bff" : "#fff")};
  border: 2px solid ${(props) => (props.checked ? "#007bff" : "#ccc")};
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s;

  svg {
    visibility: ${(props) => (props.checked ? "visible" : "hidden")};
  }
`

const CheckIcon = styled.div`
  color: ${(props) => (props.checked ? "#fff" : "#ccc")};
  font-size: 16px;
  visibility: visible;
`

const TitleFont = styled.h2`
  font-weight: 700;
`
const ShoppingCartList = () => {
  const [list, setList] = useRecoilState(shoppingCart)
  console.log("23장바구니", list)
  const [checkedAll, setCheckedAll] = useState(true)
  const [checkedItems, setCheckedItems] = useState([])
  const fee = useRecoilValue(deliveryFee)
  const navi = useNavigate()
  useEffect(() => {
    // Set initial state based on list items
    setCheckedItems(list.map((item) => item.id))
    setCheckedAll(true)
  }, [list])

  const handleChange = () => {
    const newCheckedAll = !checkedAll
    setCheckedAll(newCheckedAll)
    if (newCheckedAll) {
      setCheckedItems(list.map((item) => item.id))
    } else {
      setCheckedItems([])
    }
  }

  const handleItemChange = (itemId, checked) =>
    setCheckedItems((prev) => {
      if (checked) {
        return [...prev, itemId]
      } else {
        return prev.filter((id) => id !== itemId)
      }
    })

  useEffect(
    () => setCheckedAll(list.length > 0 && checkedItems.length === list.length),
    [checkedItems, list]
  )

  const data = list.filter((item) => checkedItems.includes(item.id))
  const itemPrice = data.reduce(
    (acc, item) => acc + item.price * item.quantity,
    0
  )
  const totalPrice = itemPrice !== 0 ? itemPrice + fee : 0

  const formattedTotalPrice = totalPrice.toLocaleString()

  const handleBuy = () => {
    const checkedList = list.filter((i) => checkedItems.includes(i.id))

    navi("/Pay", {
      state: { item: checkedList },
    })
  }
  return (
    <ShoppingCartWrapper>
      {list.length === 0 ? (
        <>
          <div>
            <TitleFont>장바구니</TitleFont>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "25vh",
              }}
            >
              장바구니가 비었습니다.
            </div>
          </div>
        </>
      ) : (
        <div>
          <TitleFont>장바구니</TitleFont>
          <CustomCheckboxWrapper>
            <CustomCheckboxLabel>
              <HiddenCheckbox
                checked={checkedAll}
                onChange={() => handleChange()}
              />
              <StyledCheckbox checked={checkedAll}>
                <CheckIcon checked={checkedAll}>✓</CheckIcon>
              </StyledCheckbox>
              전체 선택
            </CustomCheckboxLabel>
          </CustomCheckboxWrapper>

          {list.map((item) => (
            <>
              <CartItem
                key={item.id}
                item={item}
                list={list}
                setList={setList}
                checked={checkedItems.includes(item.id)}
                onChange={handleItemChange}
              />
            </>
          ))}
        </div>
      )}
      {list.length !== 0 && (
        <SummaryWrapper>
          <SummaryTitle>결제정보</SummaryTitle>
          <p>상품수: {data.length}개</p>
          <p>
            상품금액:{" "}
            {data.length > 0 &&
              data
                .reduce((acc, item) => acc + item.price * item.quantity, 0)
                .toLocaleString()}
            원
          </p>
          <p>배송비: {data.length === 0 ? 0 : fee.toLocaleString()}원</p>
          <TotalPrice>총 결제금액: {formattedTotalPrice}원</TotalPrice>
          <CheckoutButton
            variant="primary"
            size="lg"
            block
            onClick={() => handleBuy()}
          >
            구매하기
          </CheckoutButton>
        </SummaryWrapper>
      )}
    </ShoppingCartWrapper>
  )
}

export default ShoppingCartList
