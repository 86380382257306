import React, { useEffect, useState } from "react"
import styled from "styled-components"
import { Button, Card } from "react-bootstrap"
import { useNavigate } from "react-router-dom"
import { format, parseISO } from "date-fns"
import { client } from "../../../API/api"

const OrderCardWrapper = styled(Card)`
  margin-top: 16px;
  border: 1px solid #e0e0e0;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  overflow: hidden;
`

const CardTitle = styled(Card.Title)`
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 10px;
  //white-space: nowrap;
  overflow: hidden;
  white-space: normal;
  word-wrap: break-word;
  word-break: break-word;

  @media (max-width: 768px) {
    font-size: 16px;
  }
`

const OrderImage = styled(Card.Img)`
  width: 100px;
  height: 100px;
  object-fit: cover;
  margin-right: 16px;
  cursor: pointer;

  @media (max-width: 768px) {
    width: 80px;
    height: 80px;
  }
  @media (max-width: 480px) {
    width: 60px;
    height: 60px;
  }
`

const OrderDetails = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
`

const OrderInfo = styled.div`
  flex: 1;
  margin-left: 10px;
  min-width: 0;

  p {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-bottom: 5px;
  }
`

const OrderStatus = styled.div`
  font-size: 14px;
  color: #757575;
`

const ItemAmount = styled.span`
  font-weight: bold;
`

const DeliveryStatus = styled.div`
  font-size: 16px;
  font-weight: bold;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: ${(props) => {
    if (props.status === "배송 준비중") return "orange"
    if (props.status === "배송중") return "blue"
    if (props.status === "배송완료") return "green"
    return "black"
  }};
  margin-bottom: 10px;
`

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 20%;

  button {
    width: 100%;
    margin-top: 5px;
  }

  @media (max-width: 480px) {
    width: 100%;
    flex-direction: column;
    align-items: center;

    button {
      width: 100%;
    }
  }
`

const LeftContainer = styled.div`
  width: 78%;
  min-width: 0;
`

const MyOrderCard = ({ order, orderItem }) => {
  const navigate = useNavigate()
  const [data, setData] = useState(null)
  const navi = useNavigate()

  const formatDate = (dateString) => {
    if (!dateString) return "Invalid date"
    try {
      const date = parseISO(dateString)
      return format(date, "yyyy-MM-dd")
    } catch (error) {
      console.error("Error parsing date:", error)
      return "Invalid date"
    }
  }

  useEffect(() => {
    const fetchData = async () => {
      const response = await client.get(
        `/Product/getProductById?id=${orderItem.productId}`
      )
      setData(response.data)
    }
    fetchData()
  }, [orderItem.productId])

  return (
    <OrderCardWrapper>
      <Card.Body>
        <OrderDetails>
          <LeftContainer>
            <DeliveryStatus status={order.deliveryStatus}>
              {order.deliveryStatus ? "배송완료" : "배송 준비중"}
            </DeliveryStatus>
            <div style={{ display: "flex" }}>
              <OrderImage
                src={data?.photoUrl || ""}
                alt={orderItem.productId}
                onClick={() => navi(`/item/${orderItem.productId}`)}
              />
              <OrderInfo>
                <CardTitle>{data?.name || "제품명"}</CardTitle>
                <Card.Text>
                  <ItemAmount>{data?.price || 0}원</ItemAmount> /{" "}
                  {orderItem.quantity}개
                </Card.Text>
                <Card.Text>주문번호: {order.orderId}</Card.Text>
              </OrderInfo>
            </div>
          </LeftContainer>

          <ButtonContainer>
            {order.deliveryStatus && (
              <Button variant="outline-primary" size="sm">
                배송조회
              </Button>
            )}
            <Button variant="outline-dark" size="sm">
              교환,반품 신청
            </Button>
            <Button
              variant="outline-dark"
              size="sm"
              onClick={() => navi(`/QnaForm/${orderItem.productId}`)}
            >
              문의하기
            </Button>
            {order.deliveryStatus && (
              <Button
                variant="outline-dark"
                size="sm"
                onClick={() =>
                  navigate(
                    `/ReviewForm/${orderItem.productId}/${orderItem.orderItemId}`
                  )
                }
              >
                리뷰작성
              </Button>
            )}
          </ButtonContainer>
        </OrderDetails>
      </Card.Body>
    </OrderCardWrapper>
  )
}

export default MyOrderCard
