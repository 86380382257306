export const DashboardForm = ({ img, title, children, noPadding = false }) => {
  return (
    <>
      <div style={{ display: "flex", alignItems: "center" }}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            fontSize: "28px",
            lineHeight: "1",
            background: "#357ddc",
            width: "40px",
            height: "40px",
            borderRadius: "50%",
            color: "white", // 이미지 컬러가 보이도록 텍스트 컬러 추가
          }}
        >
          {img}
        </div>
        <div
          style={{
            fontSize: "20px",
            fontWeight: "600",
            marginLeft: "10px",
            lineHeight: "1",
          }}
        >
          {title}
        </div>
      </div>

      <div
        style={{
          border: "2px solid #cbc8c1",
          padding: noPadding ? "0px" : "0px 10px",
          marginTop: "10px",
          borderRadius: "5px",
          height: "520px",
        }}
      >
        {children}
      </div>
    </>
  )
}
