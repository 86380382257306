import React, { useEffect, useState } from "react"
import QnaListForm from "../../ConBox/Common/QnaListForm"
import { client } from "../../API/api"
import { useNavigate } from "react-router-dom"

function DashBoardQna() {
  const [qnaData, setQnaData] = useState()
  const [selectedQnaId, setSelectedQnaId] = useState()
  const navi = useNavigate()
  useEffect(() => {
    const fetchQnaData = async () => {
      try {
        const response = await client.get(`/Qna/getAllQna?page=0&size=10`)
        setQnaData(response.data)
      } catch (error) {
        console.error(error)
      }
    }
    fetchQnaData()
  }, [])

  const moveToQna = (item) => {
    console.log("선택한거", item)
    navi("/Admin/QnaReviewManage", { state: { item: item } })
  }
  return (
    <div>
      <QnaListForm
        qnaData={qnaData}
        setSelectedQnaId={moveToQna}
        isAdmin={true}
        isDashBoard={true}
      />
    </div>
  )
}

export default DashBoardQna
