import React, { useEffect, useRef, useState } from "react"
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import { useRecoilState } from "recoil"
import { imageSlidePositionState } from "../../../../App"
import "./CSS/ImageSlide.css"
import styled from "styled-components"
import { client } from "../../../API/api"

const CustomDots = styled.div`
  width: 100%;
  position: absolute;
  bottom: 24px;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 480px) {
    bottom: 0px;
  }
`

const ImageSlide = () => {
  const imageSlideRef = useRef(null)
  const [, setImageSlidePosition] = useRecoilState(imageSlidePositionState)
  useEffect(() => {
    if (imageSlideRef.current) {
      setImageSlidePosition(imageSlideRef.current.getBoundingClientRect())
    }
  }, [setImageSlidePosition])

  const [image, setImage] = useState([])
  useEffect(() => {
    const fetchData = async () => {
      const response = await client.get(`/Banner/findAllBanner`)
      setImage(response.data)
    }
    fetchData()
  }, [])

  const setting = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    arrows: true,
    pauseOnHover: true,
    accessibility: true,
    appendDots: (dots) => (
      <CustomDots>
        <ul> {dots} </ul>
      </CustomDots>
    ),
    dotsClass: "dots_custom",
  }
  return (
    <div ref={imageSlideRef} className="imageSlide">
      {image.length > 1 && (
        <Slider {...setting}>
          {image.map((img, index) => (
            <div>
              <img src={img.photoUrl} alt={img.id} style={{ width: "100%" }} />
            </div>
          ))}
        </Slider>
      )}
      {image.length >= 1 && (
        <div>
          <img
            src={image[0].photoUrl}
            alt={image[0].id}
            style={{ width: "100%" }}
          />
        </div>
      )}
    </div>
  )
}

export default ImageSlide
