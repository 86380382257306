// AdminOrderTable.style.jsx
import styled from "styled-components"
import { Button } from "react-bootstrap"

export const TableContainer = styled.div`
  margin-top: 30px;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 100%;
  min-height: 300px;
  //max-height: 600px;
  //overflow-y: auto;
  overflow-x: scroll;
`

export const TableHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  width: 100%;
`

export const TableCustomButton = styled(Button)`
  margin-left: 10px;
  background-color: #007bff;
  border: none;

  &:hover {
    background-color: #0056b3;
  }
`

export const StyledTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  table-layout: fixed;

  th,
  td {
    padding: 12px;
    text-align: left;
    border-bottom: 1px solid #ddd;
  }

  th {
    background-color: #f4f4f4;
  }

  tr:hover {
    background-color: #f1f1f1;
  }
`

export const TableHead = styled.thead`
  background-color: #f8f9fa;
`

export const TableRow = styled.tr`
  cursor: pointer;
`
