import React from "react"
// import DesktopSideMenu from "../../Header/Menu/DesktopSideMenu"
import DesktopSideMenu from "../../Header/Menu/DesktopSideMenu"

export default function MyPage() {
  return (
    <div>
      <DesktopSideMenu />
    </div>
  )
}
