import React, { useEffect, useRef, useState } from "react"
import * as XLSX from "xlsx"
import {
  StyledTable,
  TableContainer,
  TableCustomButton,
  TableHead,
  TableHeader,
  TableRow,
} from "./AdminOrderTable.style"
import { client } from "../../API/api"
import PaginationComponent from "../../Common/PaginationComponent"

export function AdminOrderTable() {
  const [orders, setOrders] = useState({ list: [] })
  const [page, setPage] = useState(0)
  const [size, setSize] = useState(10)

  // 데이터 fetching
  const fetchData = async () => {
    const response = await client.get(
      `/Order/findAll?page=${page}&size=${size}`
    )
    setOrders(response.data)
  }

  useEffect(() => {
    fetchData()
  }, [page, size])

  // 날짜 포맷 함수
  const formatDate = (dateString) => {
    const date = new Date(dateString)
    return date.toLocaleString("ko-KR", { timeZone: "Asia/Seoul" })
  }

  // 엑셀 다운로드
  const handleDownloadExcel = () => {
    const formattedData = orders.list.map((order) => ({
      주문번호: order.orderId,
      고객명: order.customerName,
      전화번호: order.phoneNumber,
      주소: order.address,
      상세주소: order.detailAddress,
      우편번호: order.postCode,
      주문일자: formatDate(order.orderDate),
      주문상태: order.statement,
      배송요청사항: order.orderRequest,
      상품개수: order.orderItemList.reduce(
        (acc, item) => acc + item.quantity,
        0
      ),
      총주문금액: order.totalPrice,
      송장번호: order.waybillNumber,
    }))

    const worksheet = XLSX.utils.json_to_sheet(formattedData)
    const workbook = XLSX.utils.book_new()
    XLSX.utils.book_append_sheet(workbook, worksheet, "Orders")
    XLSX.writeFile(workbook, "주문목록.xlsx")
  }

  // 송장번호 엑셀 받기
  const handleDownloadTrackingExcel = () => {
    const filteredOrders = orders.list.filter((order) => order.waybillNumber)
    const worksheet = XLSX.utils.json_to_sheet(filteredOrders)
    const workbook = XLSX.utils.book_new()
    XLSX.utils.book_append_sheet(workbook, worksheet, "송장목록")
    XLSX.writeFile(workbook, "송장목록.xlsx")
  }

  // 엑셀 파일 업로드 후 송장번호 업데이트
  const handleUploadExcel = (event) => {
    const file = event.target.files[0]
    if (!file) return

    const reader = new FileReader()
    reader.onload = (e) => {
      const data = new Uint8Array(e.target.result)
      const workbook = XLSX.read(data, { type: "array" })
      const sheet = workbook.Sheets[workbook.SheetNames[0]]
      const jsonData = XLSX.utils.sheet_to_json(sheet)

      // 엑셀에서 받은 데이터로 주문번호에 맞는 송장번호 업데이트
      const updatedOrders = orders.list.map((order) => {
        // 주문번호가 매칭되는지 확인
        const matchingEntry = jsonData.find(
          (row) => row["주문번호"] === order.orderId
        )
        // 매칭된 데이터가 있다면 송장번호를 업데이트
        return matchingEntry
          ? { ...order, waybillNumber: matchingEntry["송장번호"] }
          : order
      })

      setOrders((prevState) => ({
        ...prevState,
        list: updatedOrders,
      }))
    }

    reader.readAsArrayBuffer(file)
  }
  const fileInputRef = useRef(null)
  const handleButtonClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click()
    }
  }

  const handleSave = async () => {
    const waybillArray = orders.list
      .filter((order) => order.waybillNumber !== "NA")
      .map((order) => ({
        orderId: order.orderId,
        waybillNumber: order.waybillNumber,
      }))

    console.log("waybillArray:", waybillArray)

    try {
      // 각 주문별로 개별 요청을 보냄 (비동기 병렬 처리)
      await Promise.all(
        waybillArray.map(async (order) => {
          try {
            await client.put("/Order/updateWaybill", order)
          } catch (error) {
            console.error(`Error updating order ${order.orderId}:`, error)
          }
        })
      )

      alert("모든 송장번호가 성공적으로 업데이트되었습니다.")
    } catch (error) {
      console.log("전체 업데이트 중 오류 발생:", error)
      alert("일부 송장번호 업데이트에 실패했습니다.")
    }
  }

  return (
    <>
      <TableContainer>
        <TableHeader>
          <p>주문 건수: {orders.totalElements}건</p>
          <div style={{ display: "flex", marginLeft: "auto" }}>
            <TableCustomButton size="sm" onClick={handleDownloadExcel}>
              엑셀 받기
            </TableCustomButton>
            <TableCustomButton size="sm" onClick={handleDownloadTrackingExcel}>
              송장 내용 엑셀 받기
            </TableCustomButton>
            <TableCustomButton size="sm" onClick={handleButtonClick}>
              송장번호 엑셀 업로드
            </TableCustomButton>
            <input
              id="upload-excel"
              type="file"
              accept=".xlsx, .xls"
              ref={fileInputRef}
              style={{ display: "none" }}
              onChange={handleUploadExcel}
            />

            <TableCustomButton onClick={() => handleSave()}>
              저장하기
            </TableCustomButton>
          </div>
        </TableHeader>

        <StyledTable>
          <TableHead>
            <tr>
              <th style={{ width: "200px" }}>주문번호</th>
              <th style={{ width: "120px" }}>고객명</th>
              <th style={{ width: "150px" }}>전화번호</th>
              <th style={{ width: "300px" }}>주소</th>
              <th style={{ width: "300px" }}>상세주소</th>
              <th style={{ width: "100px" }}>우편번호</th>
              <th style={{ width: "180px" }}>주문일자</th>
              <th style={{ width: "120px" }}>주문상태</th>
              <th style={{ width: "120px" }}>배송 요구 사항</th>
              <th style={{ width: "100px" }}>상품개수</th>
              <th style={{ width: "120px" }}>총 주문금액</th>
              <th style={{ width: "150px" }}>송장번호</th>
            </tr>
          </TableHead>
          <tbody>
            {orders.list &&
              orders.list.length > 0 &&
              orders.list.map((order) => (
                <TableRow key={order.orderId}>
                  <td style={{ overflow: "hidden" }}>{order.orderId}</td>
                  <td>{order.customerName}</td>
                  <td>{order.phoneNumber}</td>
                  <td style={{ overflow: "hidden" }}>{order.address}</td>
                  <td style={{ overflow: "hidden" }}>{order.detailAddress}</td>
                  <td>{order.postCode}</td>
                  <td>{formatDate(order.orderDate)}</td>
                  <td>{order.statement}</td>
                  <td style={{ overflow: "hidden" }}>{order.orderRequest}</td>

                  <td>
                    {order.orderItemList.reduce(
                      (acc, item) => acc + item.quantity,
                      0
                    )}
                  </td>
                  <td>{order.totalPrice}</td>
                  <td style={{ overflow: "hidden" }}>
                    {order.waybillNumber || "미등록"}
                  </td>
                </TableRow>
              ))}
          </tbody>
        </StyledTable>
      </TableContainer>

      <div
        style={{ display: "flex", justifyContent: "center", marginTop: "10px" }}
      >
        <PaginationComponent
          currentPage={page}
          totalPages={orders.totalPages}
          onPageChange={setPage}
        />
      </div>
    </>
  )
}
