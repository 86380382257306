import React from "react"
import "../../CSS/Remote/RemoteNode.css"
import { CloseButton } from "react-bootstrap"

const RemoteNode = ({ item, onDelete }) => {
  const truncateText = (text, maxLength) => {
    if (text.length > maxLength) {
      return text.substring(0, maxLength) + "..."
    }
    return text
  }
  console.log("프로덕트네임", item.productName)

  return (
    <div className="remote-node-container" style={{ position: "relative" }}>
      <div
        className="remote-node-image"
        style={{ width: "140px", height: "140px" }}
      >
        <img
          style={{
            height: "140px",
            width: "140px",
            objectFit: "cover",
            objectPosition: "center",
            overflow: "hidden",
            zIndex: 0, // Set to 0 to be below the CloseButton
          }}
          src={item.productImageUrl}
          alt="Cart"
        />
      </div>
      {/* 닫기 버튼 */}
      <CloseButton
        onClick={() => onDelete(item.id)}
        style={{
          position: "absolute", // Ensure it's positioned correctly
          top: "0px",
          right: "0px",
          zIndex: 1, // Make sure it's above the image
          cursor: "pointer",
        }}
      />
      <div>
        <span className="remote-node-name">
          {truncateText(item.productName, 10)}
        </span>
      </div>
      <div className="remote-node-price">
        <span>{item.quantity * item.price.toLocaleString()}원</span>
      </div>
    </div>
  )
}

export default RemoteNode
