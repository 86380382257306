import React, { useEffect, useState } from "react"
import { Modal } from "react-bootstrap"
import styled from "styled-components"
import "./MainPopup.css"

export default function MainPopup({
  setShowMainPop,
  popupUrl,
  isAdmin = false,
}) {
  const [imgIndex, setImgIndex] = useState(0)

  useEffect(() => {
    if (!isAdmin) {
      const homeVisited = localStorage.getItem("HOME_VISITED")
      if (homeVisited && new Date() < new Date(homeVisited)) {
        setShowMainPop(false)
      }
    }
  }, [setShowMainPop])

  const closeModal = () => setShowMainPop(false)

  const closeTodayModal = () => {
    if (!isAdmin) {
      const expires = new Date()
      expires.setTime(expires.getTime() + 24 * 60 * 60 * 1000)
      localStorage.setItem("HOME_VISITED", expires.toISOString())
    }
    closeModal()
  }

  const goPrev = () => {
    setImgIndex((prev) => (prev > 0 ? prev - 1 : popupUrl.length - 1))
  }

  const goNext = () => {
    setImgIndex((prev) => (prev < popupUrl.length - 1 ? prev + 1 : 0))
  }

  return (
    <div>
      <StyledModal show onHide={closeModal} centered>
        <div className="modal-content">
          <ImgWrapper>
            {imgIndex !== 0 && (
              <ArrowButton className="prev-arrow" onClick={goPrev}>
                &#10094;
              </ArrowButton>
            )}
            <ImgStyle>
              <img src={popupUrl[imgIndex].photoUrl} alt="공지" />
            </ImgStyle>
            {imgIndex !== popupUrl.length - 1 && (
              <ArrowButton className="next-arrow" onClick={goNext}>
                &#10095;
              </ArrowButton>
            )}
          </ImgWrapper>
        </div>

        <CloseStyle>
          <Close onClick={closeTodayModal}>오늘 하루 열지 않기</Close>
          <Close onClick={closeModal}>닫기</Close>
        </CloseStyle>
      </StyledModal>
    </div>
  )
}

const StyledModal = styled(Modal)`
  display: flex;

  @media (max-width: 480px) {
    display: flex;
    width: 100vw;
    justify-content: center;
  }
  .modal-content {
    background-color: transparent;
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;

    @media (max-width: 480px) {
      display: flex;
      width: 300px;
      justify-content: center;
    }
  }
`

const ImgWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  @media (max-width: 480px) {
    width: 300px;
  }
`

const ImgStyle = styled.div`
  img {
    display: block;
    width: 500px;
    height: auto;
    @media (max-width: 480px) {
      width: 300px;
    }
  }
`

const ArrowButton = styled.button`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  color: white;
  border: none;
  padding: 15px 20px; /* 버튼 크기 조정 */
  font-size: 32px; /* 화살표 크기 증가 */
  cursor: pointer;
  border-radius: 50%;
  background-color: transparent; /* 반투명 배경 추가 */
  transition: 0.3s;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    background: transparent; /* 호버 시 더 어두운 배경 */
  }

  &.prev-arrow {
    left: -50px; /* 왼쪽 위치 조정 */
  }

  &.next-arrow {
    right: -50px; /* 오른쪽 위치 조정 */
  }
`

const CloseStyle = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  background-color: #282828;
  padding: 15px;
  border-radius: 0 0 15px 15px;
  color: #ffffff;
  margin-left: auto;
  margin-right: auto;
`

const Close = styled.span`
  cursor: pointer;
`
