import React from "react"
import styled from "styled-components"
import { Button } from "react-bootstrap"
import { Cart3 } from "react-bootstrap-icons"
import { useNavigate } from "react-router-dom"
import { useRecoilState } from "recoil"
import { shoppingCart } from "../../App"

// 플로팅 버튼 스타일
const FloatingButton = styled(Button)`
  position: fixed;
  bottom: 20px;
  right: 20px;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  background-color: #357ddc;
  z-index: 1000; /* 다른 요소 위에 위치하게 합니다 */
`

// 배지 스타일
const Badge = styled.div`
  position: absolute;
  top: -5px;
  right: -5px;
  background-color: red;
  color: white;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
`

export default function FloatCartBtn() {
  const [list, setList] = useRecoilState(shoppingCart)
  const navi = useNavigate()
  const totalQuantity = list.reduce((total, item) => total + item.quantity, 0)

  return (
    <div className="App">
      <FloatingButton variant="primary" onClick={() => navi("/MyPage/Cart")}>
        <Cart3 />
        {list.length > 0 && <Badge>{totalQuantity}</Badge>}
      </FloatingButton>
    </div>
  )
}
