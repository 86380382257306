import React, { useEffect, useState } from "react"
import styled from "styled-components"
import { Button, ButtonGroup, Container, Form } from "react-bootstrap"
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import { ko } from "date-fns/locale"
import { client } from "../../../API/api"
import PaginationComponent from "../../../Common/PaginationComponent"
import MyOrderList from "./MyOrderList"

const OrderInquiryWrapper = styled(Container)`
  margin-top: 20px;
`

const FilterWrapper = styled.div`
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;

  @media (max-width: 480px) {
    align-items: flex-start;
  }
`

const DateWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-right: 10px;
`

const OrdersGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 20px;
  margin-top: 20px;
`

const ButtonRow = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  flex-wrap: wrap;

  @media (max-width: 480px) {
    flex-direction: column;
    align-items: stretch;
  }
`

const TitleFont = styled.h2`
  font-weight: 700;
`

const PaginationWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
`

const MyOrderPage = () => {
  const [startDate, setStartDate] = useState(
    new Date(new Date().setMonth(new Date().getMonth() - 1))
  )
  const [endDate, setEndDate] = useState(new Date())
  const [filteredOrders, setFilteredOrders] = useState([]) // 필터링된 데이터
  const [page, setPage] = useState(0) // 페이지 번호 (1부터 시작)
  const [orders, setOrders] = useState([]) // 전체 데이터

  // ✅ 최초 한 번만 데이터를 불러옴
  useEffect(() => {
    fetchData()
  }, [])

  // ✅ API에서 전체 주문 데이터 가져오기
  const fetchData = async () => {
    try {
      const response = await client.get(
        `/Order/selectOrderByMember?page=0&size=100&userId=${localStorage.getItem("userId")}`
      )
      console.log("📌 주문 데이터:", response.data)
      setOrders(response.data.list || []) // 전체 데이터 저장
      setFilteredOrders(response.data.list || []) // 초기 필터링 상태
    } catch (error) {
      console.error("⚠️ 데이터 가져오기 실패:", error)
    }
  }

  // ✅ 주문 목록 필터링
  const filterOrders = () => {
    if (!orders || orders.length === 0) {
      setFilteredOrders([]) // 데이터가 없으면 빈 배열로 설정
      return
    }

    const filtered = orders.filter((order) => {
      const orderDate = new Date(order.orderDate)
      return orderDate >= startDate && orderDate <= endDate
    })

    console.log("✅ 필터링된 주문:", filtered)
    setFilteredOrders(filtered) // 필터링된 결과 업데이트
  }

  // ✅ 날짜 범위 버튼 클릭 시
  const handleDateRangeClick = (months) => {
    setEndDate(new Date())
    setStartDate(new Date(new Date().setMonth(new Date().getMonth() - months)))
  }

  // ✅ 현재 페이지에 맞는 데이터 가져오기
  const itemsPerPage = 5
  const paginatedOrders = filteredOrders.slice(
    page * itemsPerPage,
    (page + 1) * itemsPerPage
  )
  // ✅ 페이지 변경 시 데이터 재요청 없이 기존 데이터에서 slice 적용
  const handlePageChange = (newPage) => {
    console.log("📌 페이지 변경:", newPage)
    setPage(newPage) // API 요청 없이 상태만 변경
  }

  return (
    <OrderInquiryWrapper>
      <TitleFont>주문 조회</TitleFont>
      <FilterWrapper>
        <Form>
          <ButtonRow>
            <ButtonGroup>
              <Button
                variant="outline-primary"
                onClick={() => handleDateRangeClick(3)}
              >
                3개월
              </Button>
              <Button
                variant="outline-primary"
                onClick={() => handleDateRangeClick(6)}
              >
                6개월
              </Button>
              <Button
                variant="outline-primary"
                onClick={() => handleDateRangeClick(12)}
              >
                1년
              </Button>
            </ButtonGroup>
            <DateWrapper>
              <DatePicker
                showIcon
                locale={ko}
                selected={startDate}
                onChange={(date) => setStartDate(date)}
                selectsStart
                startDate={startDate}
                endDate={endDate}
                dateFormat="yyyy년 MM월"
                showMonthYearPicker
                className="form-control"
              />
              <div style={{ marginLeft: "10px", marginRight: "10px" }}>~</div>
              <DatePicker
                showIcon
                locale={ko}
                selected={endDate}
                onChange={(date) => setEndDate(date)}
                selectsEnd
                startDate={startDate}
                endDate={endDate}
                dateFormat="yyyy년 MM월"
                showMonthYearPicker
                className="form-control"
              />
            </DateWrapper>
            <Button className="w-100" onClick={filterOrders}>
              검색
            </Button>
          </ButtonRow>
        </Form>
      </FilterWrapper>

      {/* ✅ 필터링된 주문 목록 표시 */}
      <OrdersGrid>
        {paginatedOrders.length > 0 ? (
          paginatedOrders.map((orders, index) => (
            <MyOrderList key={index} orders={orders} />
          ))
        ) : (
          <div>주문 내역이 없습니다.</div>
        )}
      </OrdersGrid>

      {/* ✅ 페이징 처리 */}
      <PaginationWrapper>
        {filteredOrders.length > itemsPerPage && (
          <PaginationComponent
            currentPage={page}
            totalPages={Math.ceil(filteredOrders.length / itemsPerPage)}
            onPageChange={handlePageChange} // ✅ API 요청 없이 상태 변경만 수행
          />
        )}
      </PaginationWrapper>
    </OrderInquiryWrapper>
  )
}

export default MyOrderPage
